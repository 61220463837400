import React from 'react'
import Helmet from 'react-helmet'
import { Link, graphql } from 'gatsby'
import styled from 'styled-components'
import Layout from '@components/Layout'
import PageTitle from '@components/PageTitle'

const IndexPage = ({ data }) => {
  const homeEvents = data.eventshome.edges
  const homeNews = data.newshome.edges
  const home = data.homepage

  return (
    <Layout>
      <Helmet
        meta={[
          {
            name: 'google-site-verification',
            content: 'H7QZetZql6eqHJ1T2EiRcogOdDqrwqROJE-Pd9U09Y8',
          },
        ]}
      />
      <Section>
        <PageTitle title={home.title} />
        <div
          dangerouslySetInnerHTML={{
            __html: home.welcomeTextNode.childMarkdownRemark.html,
          }}
        />

        <Subheading>Recent News</Subheading>
        {homeNews.map(({ node }) => (
          <Dataarray key={node.id}>
            <Link to={'news/' + node.slug}>
              <Nodetitle>{node.title}</Nodetitle>
            </Link>
            <Nodedate>{node.date}</Nodedate>
            <Nodeexcerpt>{node.contentNode.childMarkdownRemark.excerpt}</Nodeexcerpt>
          </Dataarray>
        ))}

        <Subheading>Upcoming Events</Subheading>
        {homeEvents.map(({ node }) => (
          <Dataarray key={node.id}>
            <Link to={'events/' + node.slug}>
              <Nodetitle>{node.title}</Nodetitle>
            </Link>
            <Nodedate>{node.displaydate}</Nodedate>
          </Dataarray>
        ))}
      </Section>
    </Layout>
  )
}

export const homegraph = graphql`
  {
    homepage: datoCmsHomePage {
      title
      welcomeTextNode {
        childMarkdownRemark {
          html
        }
      }
    }

    newshome: allDatoCmsNews(sort: { fields: date, order: DESC }, limit: 5) {
      edges {
        node {
          title
          id
          date(formatString: "DD MMM YYYY")
          slug
          contentNode {
            childMarkdownRemark {
              excerpt(pruneLength: 250)
            }
          }
        }
      }
    }
    eventshome: allDatoCmsEvent(filter: { archived: { eq: false } }, sort: { fields: date, order: ASC }, limit: 5) {
      edges {
        node {
          id
          title
          slug
          displaydate
        }
      }
    }
  }
`

/*Styled Components*/

const Section = styled.section``

const Subheading = styled.h2``

const Dataarray = styled.div``

const Nodetitle = styled.h2``

const Nodedate = styled.b``

const Nodeexcerpt = styled.p``

export default IndexPage
